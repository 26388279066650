body {
  font-family: "Noto Sans", sans-serif;
}

.bg-custom {
  background-color: #FFEDD6;
}

.panel-bg {
  background-color: #0778B6;
}

.custom-borderRadius {
  border-radius: 16px;
}

.a {
  color: blue;
  text-decoration: inherit;
}

.border-gray-500 {
  &::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    top: 50%;
    left: 50%;
    border-style: solid;
    border-color: #d2d6dc;
    border-width: 1px 1px 0 0;
    position: absolute;
    transform-origin: 50% 50%;
  }
}

.border-orange-500 {
  &::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    top: 50%;
    left: 50%;
    border-style: solid;
    border-color: #C97100;
    border-width: 1px 1px 0 0;
    position: absolute;
    transform-origin: 50% 50%;
  }
}

.border-gray-500 {
  &::after {
    content: '';
    display: block;
    top: 50%;
    left: 50%;
    border-style: dashed;
    border-color: #B3B3B3;
    position: absolute;
    transform-origin: 50% 50%;
  }
}

.border-orange-500 {
  &::after {
    content: '';
    display: block;
    top: 50%;
    left: 50%;
    border-style: dashed;
    border-color: #C97100;
    position: absolute;
    transform-origin: 50% 50%;
  }
}

.arrow {
  margin: 20px 15px 20px 20px;
  display: inline-block;
  position: relative;

  &.right::before {
    transform: rotate(45deg);
    margin: -5px 0 0 -18px;
  }

  &.right::after {
    width: 17px;
    height: 0;
    border-width: 1px 0 0 0;
    transform: translate(-30px, -1px);
  }

}

.img-height {
  height: 7.4rem;
}

.rounded-t-lg{
    border-radius: 16px !important;
  }
  .rounded-b-lg{
    border-radius: 16px !important;
  }
/* .bg-frame {
  background: url(../../assets/img/premiumJourney/Frame\ 1445.png) no-repeat;
  background-size: 100%;
height: 300px;
} */

.year-color {
  color: #0778B6;
}

.text-ecolor {
  color: #e2e2e2;
}

.text-gray-500 {
  color: #606060;
}

.text-orange-500 {
  color: #C97100;
}

.border-orange-500 {
  color: #C97100 !important;
}

.rotate-45 {
  rotate: 190deg;
}

.selectText {
  color: #F3772F;
}

.profile-subText {
  color: #7E7E7E;
}

.profile-name {
  color: #383838;
}

::-webkit-scrollbar {
  width: 4px !important;
}

::-webkit-scrollbar-track {
  background: #F1F1F1 !important;

}

::-webkit-scrollbar-thumb {
  background: #D9D9D9;
  border-radius: 10px;
  /* border: solid 1px black; */
}

::-webkit-scrollbar-thumb:hover {
  background: #D9D9D9 !important;
}

.main-box-shadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.sticky {
  position: sticky;
  top: 72px;
  z-index: 9;
}

.panel-bg {
  background-color: #0778B6;
  color: #fff;
}

.custom-sidebar-height {
  height: 466px;
}

.textxs {
  font-size: 10px;
}

.custom-width {
  width: 13.5rem;
}

.tab-profile {
  display: none;
}

.mob-icon {
  display: none;
}

.border-inherit {
  border-color: #F7F7F7;
}

/* .custom-sidebar-width{
    width: 13rem;
  } */
@media (min-width: 360px) and (max-width: 380px) {
  .custom-margin {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mob-w {
    width: 12rem;
  }

  .mob-w2 {
    width: 8rem;
  }

  .plantext-color {
    padding-left: 5px;
  }

  .mob-left {
    left: 9rem;
  }
}

@media (min-width: 768px) and (max-width: 820px) {
  .custom-sidebar-width {
    width: 10rem;
  }

  .arrow {
    margin: 20px 2px 20px 20px;
  }

  .plantext-color {
    color: #162020;
    font-size: 18px;
    font-weight: bold;
  }

}

@media (min-width: 821px) and (max-width: 1200px) {
  .custom-sidebar-width {
    width: 10rem;
    margin-left: 21px;
  }

  .arrow {
    margin: 20px 2px 20px 20px;
  }

  .profile-w {
    width: 9rem;
    right: 44px;
  }

  .plantext-color {
    color: #162020;
    font-size: 18px;
  }

}

@media (min-width: 1024px) and (max-width: 1200px) {
  .custom-sidebar-width {
    width: 10.5rem;
    margin-left: 21px;
  }
}

@media (min-width: 1360px) and (max-width: 1421px) {
  .custom-sidebar-width {
    width: 12.5rem;
    margin-left: 21px;
  }

  .custom-width {
    width: 14.5rem;
  }
}

@media (min-width: 768px) and (max-width: 1279px) {
  .tab-profile {
    display: block;
  }

  .textxs {
    font-size: 8px;
  }
}

@media (min-width: 1422px) and (max-width: 1600px) {
  .custom-width {
    width: 15rem;
  }

  .custom-sidebar-width {
    width: 14.5rem;
  }
}

@media (min-width: 1600px) and (max-width: 1700px) {
  .custom-width {
    width: 17rem;
  }

  .custom-sidebar-width {
    width: 16.8rem;
  }
}

@media (min-width: 1706.67px) and (max-width: 1920px) {
  .custom-width {
    width: 18rem;
  }

  .custom-sidebar-width {
    width: 17.8rem;
  }

  .res-margin {
    margin-left: 20px;
  }
}

 @media (min-width: 768px) and (max-width: 1200px) {
  /* .bg-frame {
    background: url(../../assets/img/premiumJourney/SummaryFrameMobile.png) no-repeat;
    background-size: 100% auto;
    height: 196px;
    margin-left: -10px;
  } */
}

@media (max-width: 767px) { 
  /* .bg-frame {
    background: url(../../assets/img/premiumJourney/SummaryFrameMobile.png) no-repeat;
    background-size: auto;
    height: 300px;
    margin-top: 10px !important;
   } */

  .desk-icon {
    display: none;
  }

  .mob-icon {
    display: block;
    margin-left: -7px;
  }

  .sticky {
    top: 173px;
  }
}

@media (min-width: 1280px) {
  .plantext-color {
    color: #0778B6;
  }
}

@media only screen and (max-width: 375px) {
  .mob-w2 {
    width: 9rem;
  }
}

/* .text-base {
    font-size: 1rem; 
  }
  
  .font-semibold {
    font-weight: 600; 
  }
  
  .font-normal {
    font-weight: 400; 
  }
  
  .leading-8 {
    line-height: 2rem; 
  }
  
  .list-disc {
    list-style-type: disc; 
  }
  
  .pl-4 {
    padding-left: 1rem; 
  }
  
  .mt-10 {
    margin-top: 2.5rem;
  } */