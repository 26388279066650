:root {
    --common-width: 700px;
    --common-height: 580px;
    --medium-width: 650px; /* For medium screens (tablets) */
    --medium-height: 460px; /* For medium screens */
    --large-width: 820px; /* For large screens (desktops) */
    --large-height: 670px; /* For large screens */
  }

.custombox_college {
background: #FFFFFF;
box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
border-radius: 6px;
height: var(--common-height);
width: var(--common-width);
}

.boxicon_desktop_college{
    width: 42px;
    height: 42px;
}

.customviewbox_college{
box-sizing: border-box;
width: 820px;
height: var(--common-height);
border: 1px solid #999999;
box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
border-radius: 6px;
}
.optionboxtextDesk_college{
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px; 
    text-overflow: ellipsis; /* Ellipsis for overflowed text */
    color: #0778B6;
    display: flex;          
    justify-content: center; 
    align-items: center;    
    text-align: center;
    height: 40%; 
}
.profilebox_college {
width: var(--common-width);
background: #ECFAFD;
border-radius: 6px 6px 0 0;
display: flex;
height: 160px;
}
.partition-line_college {
width: 1px;
background-color: #737373;
margin: auto 10px;
height: 60%;
}
.info {
flex: 1;
display: flex;
flex-direction: column;
}
  
.northstar{
width: 25px;
height: 25px;
}
.customname_college {
    display: flex;
    align-items: center; 
    width: auto;
    height: 50px;
    gap: 0px;
    opacity: 1;
    font-family: Noto Sans;
    font-size: 18px;
    font-weight: 100;
    line-height: 29.14px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}
.userNorthStar_college{
width: 350px;
height: auto;
font-family: 'Noto Sans';
font-style: normal;
font-weight: 450;
font-size: 15px;
line-height: 20px;
color: #141736;
}
.customline_portfolio_college {
    width: 3px;
    height: 80px;
    background-color: #737373;
    margin-top: 30px;
}


.customActionBox_college{
box-sizing: border-box;
width: 120px;
height: 118px;
border: 1px solid #0778B6;
border-radius: 10px;
margin-left: 7px;
}
.iframecomp{
    min-height: 530px;
}

@media screen and (min-width: 1500px) {
    :root {
        --common-width: var(--large-width);
        --common-height: var(--large-height);
    }
    .custombox {
        width: var(--large-width);
        height: var(--large-height);
    }
    .profilebox {
        width: var(--large-width)-2px;
        height: 120px; /* Adjust profile box height */
    }
    .customviewbox {
        width: 100%;
        height: var(--common-height);
    }
    .iframecomp{
        min-height: 640px;
    }
}