@tailwind base;
@tailwind components;
@tailwind utilities;

.swiper-button-next:after,  .swiper-button-prev:after{
    font-size: 10px!important;
  }

  .avatar.offline:before {
    content: "";
    position: absolute;
   background-color: #2EBB5E;
   height: 10px;
   width: 10px;
   border-radius: 50%;
   top: 38px;
   left: 38px;
}

/* Custom CSS for black placeholder text */
.custom-placeholder::placeholder {
  color: black; /* Or use #000 for the hex color code */
  opacity: 1; /* Ensure the opacity is set to 1 for full visibility */
}
